import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-5/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { getMyProfileEndpoint } from 'common';
import {
    LegalPersonWithAllValues,
    LegalPersonWithMissingValues,
    NaturalPersonWithAllValues,
    NaturalPersonWithLegalWithAllValues,
    NaturalPersonWithLegalWithMissingValues,
    NaturalPersonWithMissingValues,
} from './ExampleData';

const mockOptionsNatural: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: NaturalPersonWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: NaturalPersonWithMissingValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfileNaturalMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsNatural,
    url: getMyProfileEndpoint(),
};

export const MyProfileNaturalMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MyProfileNaturalMock]);

    return <div>{storyFn()}</div>;
};

const mockOptionsNaturalWithLegal: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: NaturalPersonWithLegalWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: NaturalPersonWithLegalWithMissingValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfileNaturalWithLegalMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'My Profile',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsNaturalWithLegal,
    url: getMyProfileEndpoint(),
};

export const MyProfileNaturalWithLegalMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MyProfileNaturalWithLegalMock]);

    return <div>{storyFn()}</div>;
};

const mockOptionsLegal: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: LegalPersonWithAllValues,
    },
    'With missing values': {
        status: 200,
        responseBody: LegalPersonWithMissingValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfileLegalMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'My Profile',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsLegal,
    url: getMyProfileEndpoint(),
};

export const MyProfileLegalMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MyProfileLegalMock]);

    return <div>{storyFn()}</div>;
};
