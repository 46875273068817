import React from 'react';
import { MyProfileData } from 'common';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { EditView } from '../edit-view/EditView';

export const ContactDetailsHookpageComponentUi: React.FC<{
    myProfileData?: MyProfileData;
    handleSetSeenHookpage: (path: string) => void;
}> = ({ myProfileData, handleSetSeenHookpage }) => {
    if (!myProfileData?.contactDetails) {
        return <NoConnectionNotification />;
    }

    const { contactDetails } = myProfileData;

    return <EditView contactDetails={contactDetails} handleSetSeenHookpage={handleSetSeenHookpage} />;
};
