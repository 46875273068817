import React, { useMemo, useState } from 'react';
import {
    DataOverview,
    Notification,
    NotificationStatus,
    preventSubmit,
    ValidatedCheckbox,
} from '@cp-shared-5/frontend-ui';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { Fieldset, Form } from '@vwfs-bronson/bronson-react';
import { Addresses, addressValidationSchema, AddressValidatorErrorMessagesType, CustomerType } from 'common';
import { EditViewChangeForm } from './EditViewChangeForm';
import { AddressChangeForm, getSavedData } from './getSavedData';

type EditContactAddressProps = {
    onCancel: () => void;
    addresses: Addresses;
    customerType: CustomerType;
    handleSubmit: (values: AddressChangeForm) => void;
    isMainAddress?: boolean;
};

export const EditView: React.FC<EditContactAddressProps> = ({
    onCancel,
    addresses,
    customerType,
    handleSubmit,
    isMainAddress,
}) => {
    const { t } = useTranslation('my-profile');
    const [noChangesError, setNoChangesError] = useState<boolean>(false);

    const isSameAddress = (): boolean => {
        if (addresses.mainAddress && addresses.contactAddress) {
            return (
                addresses.mainAddress.city === addresses.contactAddress.city &&
                addresses.mainAddress.zipCode === addresses.contactAddress.zipCode &&
                addresses.mainAddress.street === addresses.contactAddress.street
            );
        } else return false;
    };

    const savedData = useMemo(() => getSavedData(addresses, t, isMainAddress), [addresses, isMainAddress, t]);

    const initialValues = {
        isSameAddress: false,
        country: 'Česko',
        city: '',
        street: '',
        files: [],
        streetNumber: '',
        zipCode: '',
    };

    const errorMessages: AddressValidatorErrorMessagesType = {
        country: {
            required: t('addresses-section.edit-view.validation-error.required'),
        },
        street: {
            valid: t('addresses-section.edit-view.validation-error.street-name-not-valid'),
        },
        streetNumber: {
            required: t('addresses-section.edit-view.validation-error.required'),
            valid: t('addresses-section.edit-view.validation-error.street-number-not-valid'),
        },
        city: {
            required: t('addresses-section.edit-view.validation-error.required'),
            valid: t('addresses-section.edit-view.validation-error.city-too-long'),
        },
        zipCode: {
            required: t('addresses-section.edit-view.validation-error.required'),
            valid: t('addresses-section.edit-view.validation-error.zip-not-valid'),
        },
        files: {
            required: t('addresses-section.edit-view.validation-error.required-file'),
            maxFiles: t('addresses-section.edit-view.validation-error.max-files'),
            maxTotalSize: '',
            fileType: '',
        },
    };

    const onSubmit = (values: AddressChangeForm): void => {
        if (isEqual(values, savedData)) {
            setNoChangesError(true);
        } else {
            setNoChangesError(false);
            handleSubmit(values);
        }
    };

    const isPOCustomer = customerType === 'LEGAL_PERSON';

    return (
        <DataOverview
            title={
                isMainAddress && !isPOCustomer
                    ? t('addresses-section.edit-view.title.main')
                    : isMainAddress && isPOCustomer
                    ? t('addresses-section.edit-view.title.main-legal')
                    : t('addresses-section.edit-view.title.contact')
            }
            withoutCardEffect={true}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={addressValidationSchema(isMainAddress, isPOCustomer, errorMessages)}
            >
                {(formik): JSX.Element => (
                    <Form onSubmit={preventSubmit}>
                        {isMainAddress && !isPOCustomer ? (
                            <Notification
                                className={'u-mb'}
                                status={NotificationStatus.info}
                                text={t('addresses-section.edit-view.notification.info.description')}
                            />
                        ) : (
                            isMainAddress &&
                            isPOCustomer && (
                                <Notification
                                    className={'u-mb'}
                                    status={NotificationStatus.info}
                                    text={t('addresses-section.edit-view.notification.info.description-legal')}
                                />
                            )
                        )}
                        {!isMainAddress && !isSameAddress() && (
                            <Fieldset>
                                <Fieldset.Row>
                                    <ValidatedCheckbox
                                        name="isSameAddress"
                                        label={
                                            customerType === 'NATURAL_PERSON' ||
                                            customerType === 'NATURAL_PERSON_WITH_LEGAL_PERSONALITY'
                                                ? t('addresses-section.edit-view.natural-person')
                                                : t('addresses-section.edit-view.legal-person')
                                        }
                                    />
                                </Fieldset.Row>
                            </Fieldset>
                        )}
                        <EditViewChangeForm
                            onCancel={onCancel}
                            isPOCustomer={isPOCustomer}
                            addresses={addresses}
                            shouldPrefillForm={formik.values.isSameAddress}
                            isMainAddress={isMainAddress}
                        />
                        {noChangesError && (
                            <Notification
                                status={NotificationStatus.error}
                                text={t('addresses-section.edit-view.validation-error.no-field-changes')}
                            />
                        )}
                    </Form>
                )}
            </Formik>
        </DataOverview>
    );
};
