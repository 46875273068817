import { LegalNotice } from 'common';
import { HeroImage } from '@cp-shared-5/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

type LegalNoticeUIProps = {
    legalNotice?: LegalNotice;
};

export const LegalNoticeUi: React.FC<LegalNoticeUIProps> = ({ legalNotice }) => {
    if (!legalNotice) return null;

    const { teaser, content } = legalNotice;

    return (
        <>
            {teaser && (
                <HeroImage
                    title={teaser.title}
                    imageUrl={teaser.imageUrl}
                    shallow={false}
                    inverted={!!teaser.isInverted}
                />
            )}
            <ContentSection pageWrapSize="medium">
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </ContentSection>
        </>
    );
};
