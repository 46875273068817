import { AddressBase, AddressType, Addresses } from 'common';

export const Address1WithAllValues: AddressBase = {
    addressType: AddressType.MAIN,
    street: 'Krasnovská 31/2',
    zipCode: '07101',
    city: 'Michalovce',
    country: 'Česko',
};

export const Address2WithAllValues: AddressBase = {
    addressType: AddressType.CONTACT,
    street: 'Jiráskovo nám. 1981/6',
    zipCode: '12000',
    city: 'Nové Město',
    country: 'Česko',
};

export const Address1WithMissingValues: AddressBase = {
    addressType: AddressType.MAIN,
    street: 'Krasnovská',
    city: 'Michalovce',
};

export const Address2WithMissingValues: AddressBase = {
    addressType: AddressType.MAIN,
    zipCode: '12000',
};

export const AddressesWithAllValues: Addresses = {
    mainAddress: Address1WithAllValues,
    contactAddress: Address2WithAllValues,
};

export const AddressesWithMissingValues: Addresses = {
    mainAddress: Address1WithMissingValues,
    contactAddress: Address2WithMissingValues,
};

export const AddressesWithoutMainAddress: Addresses = {
    contactAddress: Address2WithAllValues,
};

export const AddressesWithoutContactAddress: Addresses = {
    mainAddress: Address1WithAllValues,
};

export const Address1WithoutValues: AddressBase = {
    addressType: undefined,
    street: '',
    zipCode: '',
    city: '',
};

export const ExampleMainAddress: AddressBase = {
    addressType: AddressType.MAIN,
    street: 'Krasnovská',
    city: 'Michalovce',
    zipCode: '12345',
};

export const ExampleContactAddress: AddressBase = {
    addressType: AddressType.CONTACT,
    street: 'Krasnovská',
    city: 'Michalovce',
    zipCode: '12345',
};

export const AddressesWithSameAddresses: Addresses = {
    mainAddress: ExampleMainAddress,
    contactAddress: ExampleContactAddress,
};
