import { CookiePolicy } from 'common';
import { CookiePolicy as CookiePolicyShared, HeroImage } from '@cp-shared-5/frontend-ui';
import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';
import React from 'react';

export const CookiePolicyUi: React.FC<{ cookiePolicy?: CookiePolicy }> = ({ cookiePolicy }) => {
    if (!cookiePolicy) {
        return null;
    }

    const { teaser, content } = cookiePolicy;

    return (
        <>
            {teaser && <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={false} />}
            <ContentSection>
                <PageWrap size={'medium'}>
                    <CookiePolicyShared textAboveTable={content.body} title={content.title} />
                </PageWrap>
            </ContentSection>
        </>
    );
};
