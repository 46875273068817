import React, { useEffect } from 'react';
import { Contract } from 'common';
import { useTranslation } from 'react-i18next';
import { Combobox, ErrorMessage, Layout, FormField, FormFieldLabel } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import { LicensePlate } from 'components/license-plate';
import { getContractByContractNumber } from '../utils';

export type ContractSelectionProps = {
    contracts?: Contract[];
    handleViewChange: (value: string) => void;
    hideEndOfTermCalculation?: boolean;
    hideAbroadTravel?: boolean;
    hideDevinculation?: boolean;
};

export const generalKey = 'generalKey';
export const devinculationKey = 'devinculationKey';
export const abroadTravelKey = 'abroadTravelKey';
export const endOfTermCalculationKey = 'endOfTermCalculationKey';

export const ContractSelection: React.FC<ContractSelectionProps> = ({
    contracts,
    handleViewChange,
    hideEndOfTermCalculation,
    hideAbroadTravel,
    hideDevinculation,
}) => {
    const [field, meta, helpers] = useField('optionKey');
    const { t } = useTranslation('open-request');
    const generalOptionValue = t('form.form-fields.general-request');
    const devinculationOptionValue = t('form.form-fields.devinculation');
    const abroadTravelOptionValue = t('form.form-fields.abroad-travel');
    const endOfTermCalculationOptionValue = t('form.form-fields.end-of-term-calculation');

    useEffect(() => {
        if (!field.value) {
            helpers.setValue(generalKey);
        }
    }, [helpers, field.value]);

    const getContractNumberFromKey = (key?: string): string => {
        if (!key) {
            return generalKey;
        }
        if ([devinculationKey, generalKey, endOfTermCalculationKey, abroadTravelKey].includes(key)) {
            return key;
        }
        return getContractByContractNumber(contracts, key)?.contractNumber || '';
    };

    const setValueChange = ([{ key }]: { key: string }[]) => {
        const newKey = getContractNumberFromKey(key);
        if (field.value !== newKey) {
            handleViewChange(key);
            helpers.setValue(newKey);
        }
    };

    const contractOptions = (contracts || [])
        .filter((contract) => !!contract.contractNumber)
        .sort((a) => (a.isActive ? -1 : 1))
        .map(({ contractNumber, details, productTypeLevel3 }) => (
            <Combobox.Item
                testId={`contract-selection-item-${contractNumber}`}
                optionValue={contractNumber}
                optionKey={contractNumber}
                key={contractNumber}
            >
                <Layout flush>
                    <Layout.Item>
                        <LicensePlate className="u-text-base" registrationNumber={details.vehicle?.licensePlate} />
                    </Layout.Item>
                    <Layout.Item>
                        <strong>
                            {t('form.form-fields.contract-number')} {contractNumber}
                        </strong>
                    </Layout.Item>
                    <Layout.Item>
                        <b> {t(`contracts:product-type-level-3.${productTypeLevel3}`)} </b>
                    </Layout.Item>
                    <Layout.Item>
                        {details.vehicle?.brand} {details.vehicle?.model} {details.vehicle?.vehicleType}
                    </Layout.Item>
                </Layout>
            </Combobox.Item>
        ))
        .filter(Boolean);

    const generalOption = (
        <Combobox.Item
            testId={`contract-selection-item-general`}
            optionValue={generalOptionValue}
            optionKey={generalKey}
            key={generalKey}
        >
            <b>{generalOptionValue}</b>
        </Combobox.Item>
    );

    const devinculationOption = hideDevinculation
        ? []
        : [
              <Combobox.Item
                  testId={`contract-selection-item-devinculation`}
                  optionValue={devinculationOptionValue}
                  optionKey={devinculationKey}
                  key={devinculationKey}
              >
                  <b>{devinculationOptionValue}</b>
              </Combobox.Item>,
          ];

    const abroadTravelOption = hideAbroadTravel
        ? []
        : [
              <Combobox.Item
                  testId={`contract-selection-item-abroad-travel`}
                  optionValue={abroadTravelOptionValue}
                  optionKey={abroadTravelKey}
                  key={abroadTravelKey}
              >
                  <b>{abroadTravelOptionValue}</b>
              </Combobox.Item>,
          ];

    const endOfTermCalculationOption = hideEndOfTermCalculation
        ? []
        : [
              <Combobox.Item
                  testId={'contract-selection-item-end-of-term-calculation'}
                  optionValue={endOfTermCalculationOptionValue}
                  optionKey={endOfTermCalculationKey}
                  key={endOfTermCalculationKey}
              >
                  <b>{endOfTermCalculationOptionValue}</b>
              </Combobox.Item>,
          ];

    const options = [
        generalOption,
        ...devinculationOption,
        ...abroadTravelOption,
        ...endOfTermCalculationOption,
        ...contractOptions,
    ];
    const label = t('form.picklist');
    return (
        <>
            <FormFieldLabel>{label}</FormFieldLabel>
            <FormField
                type="select"
                testId={'contract-selection'}
                id={'contract-selection'}
                name="optionKey"
                errorMessage={
                    meta.touched &&
                    meta.error && <ErrorMessage testId={'textarea-error-message'}>{meta.error}</ErrorMessage>
                }
            >
                <Combobox
                    testId={'custom-contract-select'}
                    icon="semantic-expand"
                    onChange={setValueChange}
                    name="optionKey"
                    defaultState={{ value: [{ value: generalOptionValue }] }}
                >
                    {options}
                </Combobox>
            </FormField>
        </>
    );
};
