import React from 'react';
import { MyProfileData } from 'common';
import { ContactSection } from '../contact-section';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';
import { IdentificationSection } from '../identification-section';
import { AddressesSection } from '../addresses-section';

export const MyProfileUi: React.FC<{ myProfileData?: MyProfileData }> = ({ myProfileData }) => {
    if (!myProfileData) {
        return <NoConnectionNotification />;
    }

    const { identification, contactDetails, addresses } = myProfileData;

    return (
        <>
            <IdentificationSection identification={identification} />
            <ContactSection contactDetails={contactDetails} />
            <AddressesSection customerType={identification?.type} addresses={addresses} />
        </>
    );
};
