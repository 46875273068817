import { Notification, NotificationStatus, preventSubmit, Spinner, ValidatedInput } from '@cp-shared-5/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ContactDetails,
    contactDetailsValidationSchema,
    countryCodeToDialCode,
    dialCodeToCountryCode,
    EditStatus,
    getContactDetailsEndpoint,
} from 'common';
import { PhoneInput } from 'components/my-profile/contact-section/edit-view/PhoneInput';
import { CpDataApi } from 'cp-xhr';
import {
    areContactDetailsChangedStorageKey,
    areContactDetailsConfirmedStorageKey,
    isAfterContactDetailsHookPageStorageKey,
} from 'config';
import { contactDetailsHookPagePath } from 'components/navigation/paths';

export type EditViewProps = {
    contactDetails: ContactDetails;
    handleSetSeenHookpage: (path: string) => void;
};

const testIds = {
    confirm: 'confirm-button',
    submit: 'submit-button',
    skip: 'skip-button',
    email: 'email',
    phoneNumber: 'phoneNumber',
};

export const EditView: React.FC<EditViewProps> = ({ contactDetails, handleSetSeenHookpage }) => {
    const { t } = useTranslation('contact-details-hookpage');

    const [isSubmitting, setIsSubmitting] = useState<boolean>();
    const [editStatus, setEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    const initialValues: ContactDetails = {
        email: contactDetails?.email || '',
        phoneNumber: contactDetails?.phoneNumber || '',
        countryPrefixDigits: dialCodeToCountryCode(contactDetails?.countryPrefixDigits || ''),
    };

    const handleSubmit = (contactDetails: ContactDetails): void => {
        const countryPrefixDigits = contactDetails?.countryPrefixDigits
            ? contactDetails.countryPrefixDigits[0] !== '+'
                ? `+${countryCodeToDialCode(contactDetails.countryPrefixDigits)}`
                : contactDetails.countryPrefixDigits
            : '';

        const body = {
            ...contactDetails,
            countryPrefixDigits,
        };

        setIsSubmitting(true);
        CpDataApi.post(getContactDetailsEndpoint(), body)
            .then(() => {
                window.localStorage.setItem(areContactDetailsChangedStorageKey, 'true');
                window.localStorage.setItem(isAfterContactDetailsHookPageStorageKey, 'true');
                handleSetSeenHookpage(contactDetailsHookPagePath());
            })
            .catch(() => {
                setEditStatus(EditStatus.ERROR);
                window.localStorage.setItem(areContactDetailsChangedStorageKey, '');
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const errorMessages = {
        email: {
            required: t('email.validation.required'),
            valid: t('email.validation.invalid'),
        },
        phoneNumber: {
            required: t('mobile-phone.validation.required'),
            valid: t('mobile-phone.validation.invalid'),
        },
    };

    const areNoChangesInForm = (values: ContactDetails): boolean => {
        const { phoneNumber, countryPrefixDigits, email } = initialValues;
        return (
            phoneNumber === values.phoneNumber &&
            email === values.email &&
            countryPrefixDigits === values.countryPrefixDigits
        );
    };

    const confirmExistingData = (): void => {
        window.localStorage.setItem(isAfterContactDetailsHookPageStorageKey, 'true');
        window.localStorage.setItem(areContactDetailsConfirmedStorageKey, 'true');
        handleSetSeenHookpage(contactDetailsHookPagePath());
    };

    const onSubmit = (values: ContactDetails): void =>
        areNoChangesInForm(values) ? confirmExistingData() : handleSubmit(values);

    return (
        <>
            {editStatus === EditStatus.ERROR && (
                <Notification
                    testId={'error-notification'}
                    status={NotificationStatus.error}
                    text={t('message.error.text')}
                    className={'u-mb'}
                />
            )}
            {isSubmitting ? (
                <Spinner center />
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={contactDetailsValidationSchema(errorMessages)}
                    enableReinitialize
                >
                    {(formik): JSX.Element => (
                        <Form onSubmit={preventSubmit()} data-testid="editForm">
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item>
                                            <ValidatedInput
                                                label={t('email.label')}
                                                name="email"
                                                testId={testIds.email}
                                                type="email"
                                            />
                                        </Layout.Item>
                                        <Layout.Item>
                                            <PhoneInput
                                                inputName={'phoneNumber'}
                                                selectName={'countryPrefixDigits'}
                                                testId={testIds.phoneNumber}
                                                label={t('mobile-phone.label')}
                                                defaultPrefix={initialValues?.countryPrefixDigits}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                            </Fieldset>
                            <Fieldset>
                                <Fieldset.Row>
                                    {editStatus === EditStatus.ERROR ? (
                                        <ButtonContainer center>
                                            <Button
                                                secondary
                                                onClick={() => handleSetSeenHookpage(contactDetailsHookPagePath())}
                                                testId={testIds.skip}
                                            >
                                                {t('button.later')}
                                            </Button>
                                        </ButtonContainer>
                                    ) : (
                                        <ButtonContainer center>
                                            <Button
                                                secondary
                                                testId={testIds.confirm}
                                                type="button"
                                                onClick={() => handleSetSeenHookpage(contactDetailsHookPagePath())}
                                            >
                                                {t(`button.later`)}
                                            </Button>
                                            <Button
                                                testId={testIds.submit}
                                                type="submit"
                                                onClick={(): void => {
                                                    formik.submitForm();
                                                }}
                                            >
                                                {t(`button.confirm`)}
                                            </Button>
                                        </ButtonContainer>
                                    )}
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};
