// eslint-disable @typescript-eslint/explicit-function-return-type
import {
    Contract,
    EndOfPledgeOptions,
    ProductTypeLevel3,
    StateConsolidatedCode,
    EndOfTermCalculationReason,
    OpenRequestAbroadTravelError,
} from 'common';
import { ValidatedSelectItem } from '@cp-shared-5/frontend-ui';
import { AxiosResponse } from 'axios';
import base64ToBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';
import { TFunction } from 'i18next';

export const getErrorMessages = (t: TFunction) => {
    return {
        picklist: {
            required: t('form.validation.picklist.required'),
        },
        question: {
            required: t('form.validation.question.required'),
            min: t('form.validation.question.min'),
            max: t('form.validation.question.max'),
        },
        files: {
            maxFiles: t('form.validation.files.maxFiles'),
            maxTotalSize: t('form.validation.files.maxTotalSize'),
            fileType: t('form.validation.files.fileType'),
        },
    };
};

export const getErrorMessagesDevinculation = (t: TFunction) => {
    return {
        picklist: {
            required: t('form.validation.picklist.required'),
        },
        contractList: {
            required: t('form.validation.selected-contract.required'),
        },
        endOfPledge: {
            required: t('form.validation.end-of-pledge.required'),
        },
        comment: {
            min: t('form.validation.comment.min'),
            max: t('form.validation.comment.max'),
        },
    };
};

export const getErrorMessagesAbroadTravel = (t: TFunction): OpenRequestAbroadTravelError => {
    return {
        contractList: {
            required: t('form.validation.selected-contract.required'),
        },
    };
};

export const getEndOfTermCalculationErrorMessages = (t: TFunction) => ({
    contractList: {
        required: t('form.validation.select-end-of-term-calculation.required'),
    },
    reason: {
        required: t('form.validation.reason.required'),
    },
    otherExplanation: {
        required: t('form.validation.other-explanation.required'),
    },
    comment: {
        min: t('form.validation.comment.min'),
        max: t('form.validation.comment.max'),
    },
});

export const getRadioItems = (t: TFunction) => [
    {
        label: t('form.form-fields.end-of-pledges-options.customer-invoice'),
        value: EndOfPledgeOptions.CUSTOMER_INVOICE,
    },
    {
        label: t('form.form-fields.end-of-pledges-options.customer-budget'),
        value: EndOfPledgeOptions.CUSTOMER_BUDGET,
    },
    {
        label: t('form.form-fields.end-of-pledges-options.authorized-service'),
        value: EndOfPledgeOptions.AUTHORIZED_SERVICE,
    },
    {
        label: t('form.form-fields.end-of-pledges-options.non-authorized-service'),
        value: EndOfPledgeOptions.NON_AUTHORIZED_SERVICE,
    },
];

export const mapContractsToCustomSelectItems = (contracts: Contract[], t: TFunction): ValidatedSelectItem[] =>
    contracts.map(({ contractNumber, isActive, details, encryptedContractNumber }) => {
        const label = t(`form.form-fields.${isActive ? '' : 'inactive-'}contract-option`, {
            contractNumber: contractNumber,
            registrationPlateCode: details.vehicle?.licensePlate,
        });
        return {
            label,
            key: encryptedContractNumber,
            value: encryptedContractNumber,
            testId: `contract-selection-item-${contractNumber}`,
        };
    });

export const getDevinculationContractOptions = (contracts: Contract[], t: TFunction): ValidatedSelectItem[] => {
    const sortedContracts = contracts
        .filter((contract) => !!contract.contractNumber)
        .sort((a) => (a.isActive ? -1 : 1));
    return mapContractsToCustomSelectItems(sortedContracts, t);
};

export const getEndOfTermCalculationContracts = (contracts: Contract[]) =>
    contracts.filter(
        ({ stateConsolidatedCode, productTypeLevel3, isActive }) =>
            isActive &&
            stateConsolidatedCode === StateConsolidatedCode.ACTCTR &&
            (productTypeLevel3 === ProductTypeLevel3.ICVBW ||
                productTypeLevel3 === ProductTypeLevel3.ICVBWO ||
                productTypeLevel3 === ProductTypeLevel3.FL),
    );

export const getEndOfTermCalculationReasonRadioItems = (t: TFunction) => {
    const prefix = 'form.form-fields.end-of-term-calculation-reasons';
    return [
        { value: EndOfTermCalculationReason.PAY_FROM_SAVINGS, label: t(`${prefix}.pay-from-savings`) },
        {
            value: EndOfTermCalculationReason.VEHICLE_SELLING_BUYING_NEW_VIA_VWFS,
            label: t(`${prefix}.vehicle-selling-buying-new-via-vwfs`),
        },
        { value: EndOfTermCalculationReason.VEHICLE_SELLING, label: t(`${prefix}.vehicle-selling`) },
        {
            value: EndOfTermCalculationReason.VEHICLE_SELLING_BUYING_NEW_FOR_CASH,
            label: t(`${prefix}.vehicle-selling-buying-new-for-cash`),
        },
        { value: EndOfTermCalculationReason.REFUND_CREDIT, label: t(`${prefix}.refund-credit`) },
        { value: EndOfTermCalculationReason.CANCELLING_CREDIT, label: t(`${prefix}.cancelling-credit`) },
        { value: EndOfTermCalculationReason.VEHICLE_THEFT, label: t(`${prefix}.vehicle-theft`) },
        { value: EndOfTermCalculationReason.ONLY_INFORMATION, label: t(`${prefix}.only-information`) },
        { value: EndOfTermCalculationReason.OTHER, label: t(`${prefix}.other`) },
    ];
};

export const getRegistrationPlateCode = (contracts: Contract[], encryptedContractNumber: string): string =>
    (contracts || []).find((contract) => encryptedContractNumber === contract?.encryptedContractNumber)?.details.vehicle
        ?.licensePlate || '';

export const mapReasonOptionToValue = (t: TFunction, reason?: EndOfTermCalculationReason): string => {
    const prefix = 'form.form-fields.end-of-term-calculation-reasons';
    switch (reason) {
        case EndOfTermCalculationReason.PAY_FROM_SAVINGS:
            return t(`${prefix}.pay-from-savings`);
        case EndOfTermCalculationReason.VEHICLE_SELLING_BUYING_NEW_VIA_VWFS:
            return t(`${prefix}.vehicle-selling-buying-new-via-vwfs`);
        case EndOfTermCalculationReason.VEHICLE_SELLING:
            return t(`${prefix}.vehicle-selling`);
        case EndOfTermCalculationReason.VEHICLE_SELLING_BUYING_NEW_FOR_CASH:
            return t(`${prefix}.vehicle-selling-buying-new-for-cash`);
        case EndOfTermCalculationReason.REFUND_CREDIT:
            return t(`${prefix}.refund-credit`);
        case EndOfTermCalculationReason.CANCELLING_CREDIT:
            return t(`${prefix}.cancelling-credit`);
        case EndOfTermCalculationReason.VEHICLE_THEFT:
            return t(`${prefix}.vehicle-theft`);
        case EndOfTermCalculationReason.ONLY_INFORMATION:
            return t(`${prefix}.only-information`);
        case EndOfTermCalculationReason.OTHER:
            return reason;
        default:
            return '';
    }
};

export const getContractByContractNumber = (contracts?: Contract[], contractNumber?: string): Contract | undefined =>
    contracts?.find((contract) => contract.contractNumber === contractNumber);

export const getAbroadTravelContracts = (contracts: Contract[]) =>
    contracts.filter(({ stateConsolidatedCode }) => stateConsolidatedCode !== StateConsolidatedCode.FRZ);

export const getDevinculationContracts = (contracts: Contract[]) =>
    contracts.filter(({ isActive, productTypeLevel3, details }) => {
        const isCreditTypeContract = Boolean(
            productTypeLevel3 && [ProductTypeLevel3.ICVBW, ProductTypeLevel3.ICVBWO].includes(productTypeLevel3),
        );
        const outstandingBalance = details.financial?.outstandingBalance || 0;
        const nextDueAmount = details.financial?.nextDueAmount || 0;
        const isNotInDebt =
            outstandingBalance <= 0 || (outstandingBalance > 0 && outstandingBalance <= nextDueAmount * 1.5);

        return isActive && isCreditTypeContract && isNotInDebt;
    });

export const getAbroadTravelContractOptions = (contracts: Contract[], t: TFunction): ValidatedSelectItem[] => {
    return contracts.map(({ contractNumber, details, encryptedContractNumber }) => {
        const label = t(`form.form-fields.contract-option`, {
            contractNumber,
            registrationPlateCode: details.vehicle?.licensePlate,
        });

        return {
            label,
            key: encryptedContractNumber,
            value: contractNumber,
            testId: `contract-selection-item-${contractNumber}`,
        };
    });
};

export const handleFileDownloadIfBase64 = (
    submitResponse: AxiosResponse<{ base64?: string; filename?: string }>,
): void => {
    if (submitResponse.data?.base64 && submitResponse.data?.filename) {
        const pdfBlob = base64ToBlob(submitResponse.data.base64, 'application/pdf');
        saveAs(pdfBlob, submitResponse.data.filename);
    }
};
