import React from 'react';
import { useTranslation } from 'react-i18next';
import { Contract, formatAsDate, ProductTypeLevel3 } from 'common';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';

type ContractComponentProps = {
    contract: Contract;
};

export const HolidayNotification: React.FC<ContractComponentProps> = ({ contract }) => {
    const { t } = useTranslation('contracts');
    const {
        productTypeLevel3: contractType,
        details: { financial },
    } = contract;

    const paymentHolidayStartDate = formatAsDate(financial?.paymentHolidayStartDate) || '-';

    const paymentHolidayEndDate = formatAsDate(financial?.paymentHolidayEndDate) || '-';

    const getNotificationMessage = () => {
        if (contractType === ProductTypeLevel3.ICVBW || contractType === ProductTypeLevel3.ICVBWO)
            return {
                text: t('holiday-notification.installment-credit', {
                    startDate: paymentHolidayStartDate,
                    endDate: paymentHolidayEndDate,
                }),
            };
        if (contractType === ProductTypeLevel3.OL)
            return {
                text: t('holiday-notification.operating-lease', {
                    startDate: paymentHolidayStartDate,
                    endDate: paymentHolidayEndDate,
                }),
            };
        if (contractType === ProductTypeLevel3.FL)
            return {
                text: t('holiday-notification.finance-lease', {
                    startDate: paymentHolidayStartDate,
                    endDate: paymentHolidayEndDate,
                }),
            };
    };

    return (
        <Notification
            status={NotificationStatus.warning}
            testId={'notification-holiday'}
            headline={t('holiday-notification.title')}
            className={'u-mb'}
            {...getNotificationMessage()}
        />
    );
};
