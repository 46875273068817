import React from 'react';
import { Redirect } from 'react-router-dom';
import {
    AuthenticationProvider,
    AutoLogout,
    createAuthenticationComponent,
    createAuthorization,
    createDecoratedRoute,
    Spinner,
} from '@cp-shared-5/frontend-ui';
import { landingPagePath, registrationPagePath, consentHookPagePath, logoutPath } from '../components/navigation/paths';
import { getHashedUserIdEndpoint, getUserRegistryStatusEndpoint, UserRegistryStatus } from 'common';
import { CpDataApi } from '../cp-xhr';
import { acceptedConsentDateStorageKey, consentValidDate } from '../config';
import { formatCpDate } from '@cp-shared-5/common-utilities';
import moment from 'moment-timezone';

const Authentication = createAuthenticationComponent({
    onMissingAuthentication: <Redirect to={landingPagePath()} />,
});

const authDataProvider = async (): Promise<{ isAuthorized: boolean }> => {
    try {
        const { data: registrationData } = await CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint());
        return { isAuthorized: registrationData.isRegistered };
    } catch (e) {
        return { isAuthorized: false };
    }
};

const consentDataProvider = async (): Promise<{ isAuthorized: boolean }> => {
    try {
        const { data: hashedUserId } = await CpDataApi.get<string>(getHashedUserIdEndpoint());
        const localStorageUserConsentDate = window.localStorage.getItem(acceptedConsentDateStorageKey + hashedUserId);
        const isLocalStorageConsentDateEqualToValidDate = localStorageUserConsentDate
            ? moment(localStorageUserConsentDate).isSame(consentValidDate)
            : false;
        const isTodaysDatePastValidDate = moment(formatCpDate().format('YYYY-MM-DD')).isSameOrAfter(consentValidDate);

        if (isTodaysDatePastValidDate && !isLocalStorageConsentDateEqualToValidDate) {
            return { isAuthorized: false };
        } else {
            return { isAuthorized: true };
        }
    } catch (e) {
        return { isAuthorized: false };
    }
};

const {
    Authorization: UserRegistrationAuthorization,
    AuthorizationProvider,
    useAuthorizationFlow: useRegistrationFlow,
} = createAuthorization({
    displayName: 'UserRegistrationAuthorization',
    authorizationDataProvider: authDataProvider,
    onMissingAuthorization: <Redirect to={registrationPagePath()} />,
    onLoading: <Spinner center />,
    onError: <Redirect to={landingPagePath()} />,
});

const { Authorization: UserConsentAuthorization, AuthorizationProvider: UserConsentProvider } = createAuthorization({
    displayName: 'UserConsentAuthorization',
    authorizationDataProvider: consentDataProvider,
    onMissingAuthorization: <Redirect to={consentHookPagePath()} />,
    onLoading: <Spinner center />,
    onError: <Redirect to={landingPagePath()} />,
});

const AuthProviders: React.FC = ({ children }) => (
    <AuthenticationProvider loadingComponent={<Spinner center />}>
        <AuthorizationProvider>
            <UserConsentProvider>
                <AutoLogout
                    idleTimeInMinutes={30}
                    redirectUri={`${window.location.origin}${logoutPath()}?logout-type=auto`}
                >
                    {children}
                </AutoLogout>
            </UserConsentProvider>
        </AuthorizationProvider>
    </AuthenticationProvider>
);

export const AuthenticatedRoute = createDecoratedRoute('AuthenticatedRoute', Authentication);
export const AuthorizedRoute = createDecoratedRoute(
    'AuthorizedRoute',
    Authentication,
    UserRegistrationAuthorization,
    UserConsentAuthorization,
);
export * from './AxiosConfiguration';
export { AuthProviders, useRegistrationFlow };
