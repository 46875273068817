import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Button, ContentSection, Fieldset, Layout, Modal, Spinner } from '@vwfs-bronson/bronson-react';
import { useAnalyticsActionTracker } from '@cp-shared-5/frontend-ui';
import {
    AbroadTravelRequest,
    Contract,
    DevinculationRequest,
    EndOfTermCalculationReason,
    EndOfTermCalculationRequest,
    frontEndOpenRequestDevinculationValidationSchema,
    frontEndOpenRequestEndOfTermCalculationValidationSchema,
    frontEndOpenRequestValidationSchema,
    getAbroadTravelRequestSendingEndpoint,
    getDevinculationRequestSendingEndpoint,
    getEndOfTermCalculationRequestSendingEndpoint,
    getRequestSendingEndpoint,
    openRequestAbroadTravelValidationSchema,
    Request,
} from 'common';
import { getInitialValues, RequestFormResultType } from './initialValues';
import {
    abroadTravelKey,
    ContractSelection,
    devinculationKey,
    endOfTermCalculationKey,
} from './contract-selection/ContractSelection';
import { CpDataApi } from '../../../cp-xhr';
import { dashboardPagePath } from 'components/navigation/paths';
import { useHistory } from 'react-router-dom';
import { Base64File } from '@cp-shared-5/apis';
import { getBase64 } from '../../file-upload/utils';
import {
    getAbroadTravelContracts,
    getContractByContractNumber,
    getDevinculationContracts,
    getEndOfTermCalculationContracts,
    getEndOfTermCalculationErrorMessages,
    getErrorMessages,
    getErrorMessagesAbroadTravel,
    getErrorMessagesDevinculation,
    handleFileDownloadIfBase64,
    mapReasonOptionToValue,
} from './utils';
import { DefaultFormSection } from './default-form-section';
import { DevinculationFormSection } from './devinculation-form-section';
import { EndOfTermCalculationFormSection } from './end-of-term-calculation-form-section';
import { AbroadTravelFormSection } from './abroad-travel-form-section/AbroadTravelFormSection';

enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    NONE = 'NONE',
}

enum ViewType {
    DEFAULT = 'DEFAULT',
    DEVINCULATION = 'DEVINCULATION',
    END_OF_TERM_CALCULATION = 'END_OF_TERM_CALCULATION',
    ABROAD_TRAVEL = 'ABROAD_TRAVEL',
}

export type FormViewProps = { contracts?: Contract[] };

export const FormView: React.FC<FormViewProps> = ({ contracts = [] }) => {
    const { t } = useTranslation('open-request');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentView, setCurrentView] = useState<ViewType>(ViewType.DEFAULT);
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const history = useHistory();
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');

    if (!contracts) {
        return null;
    }

    const endOfTermCalculationContracts = getEndOfTermCalculationContracts(contracts);
    const abroadTravelContracts = getAbroadTravelContracts(contracts);
    const devinculationContracts = getDevinculationContracts(contracts);

    const handleViewChange = (value: string): void => {
        switch (value) {
            case devinculationKey:
                setCurrentView(ViewType.DEVINCULATION);
                break;
            case endOfTermCalculationKey:
                setCurrentView(ViewType.END_OF_TERM_CALCULATION);
                break;
            case abroadTravelKey:
                setCurrentView(ViewType.ABROAD_TRAVEL);
                break;
            default:
                setCurrentView(ViewType.DEFAULT);
                break;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = async (values: RequestFormResultType, { resetForm }: { resetForm: any }) => {
        setIsSubmitting(true);
        const {
            optionKey,
            question = '',
            files = [],
            contractNumber,
            comment,
            endOfPledge,
            endOfTermCalculation,
            abroadTravel,
        } = values;
        let body: Request | DevinculationRequest | EndOfTermCalculationRequest | AbroadTravelRequest;
        let requestEndpointPath: string;
        let analyticsSuccessMessage: string;

        if (currentView === ViewType.END_OF_TERM_CALCULATION) {
            const { comment, otherExplanation, reason } = endOfTermCalculation;
            body = {
                contractNumber: endOfTermCalculation.contractNumber,
                reason: mapReasonOptionToValue(t, reason),
                otherExplanation: reason === EndOfTermCalculationReason.OTHER ? otherExplanation : '',
                comment,
            };
            requestEndpointPath = getEndOfTermCalculationRequestSendingEndpoint();
        } else if (currentView === ViewType.DEVINCULATION) {
            analyticsSuccessMessage = `end of pledge option: ${endOfPledge}`;
            body = {
                contractNumber,
                comment,
                endOfPledge,
            };
            requestEndpointPath = getDevinculationRequestSendingEndpoint();
        } else if (currentView === ViewType.ABROAD_TRAVEL) {
            body = {
                contractNumber:
                    getContractByContractNumber(contracts, abroadTravel.contractNumber)?.encryptedContractNumber ?? '',
            };
            requestEndpointPath = getAbroadTravelRequestSendingEndpoint();
        } else {
            analyticsSuccessMessage = `question: ${question}`;
            const filePromises: Promise<Base64File>[] = files.map((file) => getBase64(file));
            const base64Files = await Promise.all(filePromises);
            const encryptedContractNumber =
                getContractByContractNumber(contracts, optionKey)?.encryptedContractNumber || '';
            body = {
                contractNumber: encryptedContractNumber,
                question,
                files: base64Files,
            };
            requestEndpointPath = getRequestSendingEndpoint();
        }

        CpDataApi.post(requestEndpointPath, body)
            .then((response) => {
                handleFileDownloadIfBase64(response);
                onSuccess(analyticsSuccessMessage);
                resetForm({
                    values: getInitialValues(),
                });

                if (!response.data?.base64) {
                    setMessageType(MessageType.SUCCESS);
                }

                setCurrentView(ViewType.DEFAULT);
            })
            .catch(() => {
                onError();
                setMessageType(MessageType.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    const handleSuccessModalConfirm = () => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    const getValidationSchema = () => {
        switch (currentView) {
            case ViewType.DEVINCULATION:
                return frontEndOpenRequestDevinculationValidationSchema(getErrorMessagesDevinculation(t));
            case ViewType.END_OF_TERM_CALCULATION:
                return frontEndOpenRequestEndOfTermCalculationValidationSchema(getEndOfTermCalculationErrorMessages(t));
            case ViewType.ABROAD_TRAVEL:
                return openRequestAbroadTravelValidationSchema(getErrorMessagesAbroadTravel(t));
            default:
                return frontEndOpenRequestValidationSchema(getErrorMessages(t));
        }
    };

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={getValidationSchema()}
            onSubmit={handleSubmit}
            validateOnBlur={true}
        >
            {({ submitForm, values, setFieldValue, errors, touched }) => (
                <Form onSubmit={(e) => e.preventDefault()} data-testid="request-form">
                    <ContentSection>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>
                                        <ContractSelection
                                            contracts={contracts}
                                            handleViewChange={handleViewChange}
                                            hideEndOfTermCalculation={!endOfTermCalculationContracts.length}
                                            hideAbroadTravel={!abroadTravelContracts.length}
                                            hideDevinculation={!devinculationContracts.length}
                                        />
                                    </Layout.Item>
                                    {currentView === ViewType.DEVINCULATION && (
                                        <DevinculationFormSection values={values} contracts={contracts} />
                                    )}
                                    {currentView === ViewType.END_OF_TERM_CALCULATION && (
                                        <EndOfTermCalculationFormSection
                                            endOfTermCalculationValues={values.endOfTermCalculation}
                                            contracts={endOfTermCalculationContracts}
                                            setFieldValue={setFieldValue}
                                            errors={errors?.endOfTermCalculation}
                                            touched={touched?.endOfTermCalculation}
                                        />
                                    )}
                                    {currentView === ViewType.ABROAD_TRAVEL && (
                                        <AbroadTravelFormSection contracts={abroadTravelContracts} />
                                    )}
                                    {currentView === ViewType.DEFAULT && <DefaultFormSection />}
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <div className="u-text-center">
                                    {isSubmitting ? (
                                        <Spinner center />
                                    ) : (
                                        <Button testId={'submit-button'} type="submit" onClick={submitForm}>
                                            {t('form.submit-button')}
                                        </Button>
                                    )}
                                </div>
                            </Fieldset.Row>
                        </Fieldset>
                    </ContentSection>
                    <Modal
                        shown={messageType === MessageType.ERROR}
                        status="error"
                        title={t('modal.error.title')}
                        testId={'error-modal'}
                        onClose={handleModalClose}
                        onCancel={handleModalClose}
                        onClickOutside={handleModalClose}
                    >
                        {t('modal.error.description')}
                    </Modal>
                    <Modal
                        shown={messageType === MessageType.SUCCESS}
                        status="success"
                        title={t('modal.success.title')}
                        onClose={handleModalClose}
                        onConfirm={handleSuccessModalConfirm}
                        onClickOutside={handleModalClose}
                        buttonConfirmText={t('navigation:navigation.dashboard')}
                        testId={'success-modal'}
                    >
                        {t('modal.success.description')}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
