import React, { useState } from 'react';
import { Heading, Layout } from '@vwfs-bronson/bronson-react';
import { Identification, IdentificationForm } from './identification';
import {
    getRegistrationPersonEndpoint,
    Registration as RegistrationInterface,
    RegistrationError,
    RegistrationResult,
} from 'common';
import { Spinner, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import { CpDataApi } from 'cp-xhr';
import { SmsIdentification } from './sms-identification';
import { useTranslation } from 'react-i18next';

type ViewTypes = 'REGISTRATION' | 'SMS_IDENTIFICATION';

type RequestStatusProps = {
    loading: boolean;
    isError: boolean;
    errorMessage: RegistrationError | '';
    phoneNumberEnding?: string;
    customerId?: string;
};

export const VWFSVerification: React.FC = () => {
    const [currentView, setCurrentView] = useState<ViewTypes>('REGISTRATION');
    const { t } = useTranslation('registration');
    useAnalyticsPageViewTracker('confirmIdentity');
    const [requestStatus, setRequestStatus] = useState<RequestStatusProps>({
        loading: false,
        isError: false,
        errorMessage: '',
        phoneNumberEnding: '',
        customerId: '',
    });

    const [formValuesForStep1, setFormValuesForStep1] = useState<RegistrationInterface>({
        contractId: '',
        registrationPlateCode: '',
    });

    const onSubmitSuccess = (formValues: IdentificationForm): void => {
        const registrationRequestBody: RegistrationInterface = {
            contractId: formValues.contractId,
            registrationPlateCode: formValues.registrationPlateCode,
        };

        setRequestStatus({ ...requestStatus, loading: true, errorMessage: '' });

        CpDataApi.post<RegistrationResult>(getRegistrationPersonEndpoint(), registrationRequestBody)
            .then((response) => {
                setRequestStatus({
                    ...requestStatus,
                    errorMessage: '',
                    customerId: response.data.customerId,
                    phoneNumberEnding: response.data.phoneNumberEnding,
                    loading: false,
                });
                setFormValuesForStep1({
                    contractId: formValues.contractId,
                    registrationPlateCode: formValues.registrationPlateCode,
                });
                setCurrentView('SMS_IDENTIFICATION');
            })
            .catch((error) => {
                if (Object.values(RegistrationError).includes(error.response.data.code)) {
                    setRequestStatus({
                        ...requestStatus,
                        errorMessage: error.response.data.code,
                        loading: false,
                    });
                } else {
                    setRequestStatus({
                        ...requestStatus,
                        errorMessage: RegistrationError.UNEXPECTED_BEHAVIOUR,
                        loading: false,
                    });
                }
            });
    };

    return (
        <>
            {requestStatus.loading && <Spinner fullPage={true} />}
            <Layout.Item className="u-text-center">
                <Heading level="1">{t('identification.title')}</Heading>
            </Layout.Item>
            {currentView === 'REGISTRATION' && (
                <Identification onSubmitSuccess={onSubmitSuccess} errorMessage={requestStatus.errorMessage} />
            )}
            {currentView === 'SMS_IDENTIFICATION' && (
                <SmsIdentification
                    formValuesForStep1={{
                        contractId: formValuesForStep1.contractId,
                        registrationPlateCode: formValuesForStep1.registrationPlateCode,
                    }}
                    phoneNumberEnding={requestStatus.phoneNumberEnding || ''}
                    customerId={requestStatus.customerId || ''}
                />
            )}
        </>
    );
};
