import React, { useMemo, useCallback } from 'react';
import { Insurance, InsuranceCoverage, CoverageCode, CoverageDesc, FinancialDetails } from 'common';
import { GeneralSection } from '../general-section';
import { PropertyDemageSection } from '../property-demage-section';
import { CollisionDemageSection } from '../collision-demage-section';
import { SupplementaryInsuranceSection } from '../supplementary-insurance-section';

type InsuranceDetailsUiProps = {
    insurance: Insurance;
    financialDetails?: FinancialDetails;
};

export const InsuranceDetailsUi: React.FC<InsuranceDetailsUiProps> = ({ insurance, financialDetails }) => {
    const getCoveragesByCodeOrDescription = useCallback(
        (code: CoverageCode, description: CoverageDesc): InsuranceCoverage[] =>
            insurance.coverages.filter(
                (coverage: InsuranceCoverage) =>
                    coverage.coverageCode === code || coverage.coverageDesc === description,
            ),
        [insurance.coverages],
    );

    const getCoveragesOtherThanCodeAndDescription = useCallback(
        (codes: string[], descriptions: string[]): InsuranceCoverage[] =>
            insurance.coverages.filter((coverage: InsuranceCoverage) => {
                const { coverageCode, coverageDesc } = coverage;
                if (coverageCode && codes.includes(coverageCode)) return false;
                if (coverageDesc && descriptions.includes(coverageDesc)) return false;
                return true;
            }),
        [insurance.coverages],
    );

    const propertyDemageCoverages: InsuranceCoverage[] = useMemo(
        () => getCoveragesByCodeOrDescription(CoverageCode.POV, CoverageDesc.PROPERTY_DEMAGE),
        [getCoveragesByCodeOrDescription],
    );

    const collisionDemageCoverages: InsuranceCoverage[] = useMemo(
        () => getCoveragesByCodeOrDescription(CoverageCode.HAV, CoverageDesc.COLLISION_DEMAGE),
        [getCoveragesByCodeOrDescription],
    );

    const supplementaryCoverages: InsuranceCoverage[] = useMemo(
        () =>
            getCoveragesOtherThanCodeAndDescription(
                [CoverageCode.POV, CoverageCode.HAV],
                [CoverageDesc.PROPERTY_DEMAGE, CoverageDesc.COLLISION_DEMAGE],
            ),
        [getCoveragesOtherThanCodeAndDescription],
    );

    return (
        <>
            <GeneralSection insurance={insurance} />
            {propertyDemageCoverages.map((coverage, index) => (
                <PropertyDemageSection
                    coverage={coverage}
                    key={`property-demage-section-${index}`}
                    financialDetails={financialDetails}
                />
            ))}
            {collisionDemageCoverages.map((coverage, index) => (
                <CollisionDemageSection
                    coverage={coverage}
                    key={`collision-demage-section-${index}`}
                    financialDetails={financialDetails}
                />
            ))}
            <SupplementaryInsuranceSection coverages={supplementaryCoverages} />
        </>
    );
};
