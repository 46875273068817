import { ContactDetails } from 'common';

export const ContactDetailsWithAllValues: ContactDetails = {
    email: 'john@email.com',
    phoneNumber: '023456789',
    countryPrefixDigits: '+420',
};

export const ContactDetailsWithMissingEmail: ContactDetails = {
    phoneNumber: '623456789',
};

export const ContactDetailsWithMissingPhone: ContactDetails = {
    email: 'john@email.com',
};

export const ContactDetailsWithPrefixAndMissingPhone: ContactDetails = {
    email: 'john@email.com',
    countryPrefixDigits: '+420',
};

export const ContactDetailsWithoutValues: ContactDetails = {
    email: '',
    phoneNumber: '',
};
