import { Contract } from 'common';
import { MyDocumentsLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import { useContracts } from 'components/contracts';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { useMyProfile } from 'components/my-profile/useMyProfile';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MyDocumentsUi } from './ui';
import { useMyDocuments } from './useMyDocuments';
import {
    getAbroadTravelCertificationDocuments,
    getAllDocumentsWithDocumentType,
    getDevinculationDocuments,
    getDriverSetDocument,
    getGeneralTermsDocuments,
    getGreenCardDocuments,
} from './utils';

const MyDocumentWithHandlers = withLoadingAndNoConnectionHandler(MyDocumentsUi);

export const MyDocuments: React.FC = () => {
    const { data: contracts, isLoading: isLoadingContracts, loadingError: loadingErrorContracts } = useContracts();
    const { data: myProfileData, isLoading: isLoadingMyProfile, loadingError: loadingErrorMyProfile } = useMyProfile();
    const { data: documents, isLoading: isLoadingDocuments } = useMyDocuments();

    const isLoading: boolean = isLoadingContracts || isLoadingMyProfile || isLoadingDocuments;
    const loadingError: boolean = !!loadingErrorContracts || !!loadingErrorMyProfile;

    const { t } = useTranslation(['my-documents', 'contracts']);
    useAnalyticsPageViewTracker('postbox', !loadingError);

    const getMyDocumentsList = (contracts: Contract[], t: TFunction) => {
        const generalTermsDocuments = getGeneralTermsDocuments(contracts, t);
        const driverSetDocument = getDriverSetDocument(contracts, t, myProfileData);
        const allDocumentsWithDocumentType = getAllDocumentsWithDocumentType(contracts, t, documents?.documents);
        const greenCardDocuments = getGreenCardDocuments(contracts, t, myProfileData);
        const abroadTravelCertificationDocuments = getAbroadTravelCertificationDocuments(contracts, t);
        const devinculationDocuments = getDevinculationDocuments(contracts, t);

        return [
            ...generalTermsDocuments,
            ...driverSetDocument,
            ...allDocumentsWithDocumentType,
            ...greenCardDocuments,
            ...abroadTravelCertificationDocuments,
            ...devinculationDocuments,
        ];
    };

    const myDocuments = contracts ? getMyDocumentsList(contracts, t) : [];

    return (
        <MyDocumentWithHandlers
            myDocuments={myDocuments}
            isLoading={isLoading}
            hasError={loadingError}
            loadingPlaceholder={<MyDocumentsLoadingPlaceholder />}
        />
    );
};
