import React from 'react';
import { GroupedInstallment, groupPartialInstallments, Installment, ProductTypeLevel3 } from 'common';
import {
    createDynamicTable,
    DynamicTableFormatOptions,
    DynamicTableSortOptions,
    TableHeaderEntry,
} from '@cp-shared-5/frontend-ui';
import { generateRow, generateOLRow, getColumnHeadings, getOLColumnHeadings, ToColumnValues } from './utils';
import { useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';

export type AmortizationTableInstallmentOverviewProps = {
    installments?: Installment[];
    productType?: ProductTypeLevel3;
};

const DynamicTable = createDynamicTable<GroupedInstallment>();

export const AmortizationTableInstallmentOverview: React.FC<AmortizationTableInstallmentOverviewProps> = ({
    installments,
    productType,
}) => {
    const { t, f } = useTranslationWithFormatting('amortization-details');

    if (!installments) return null;

    const formatOptions: DynamicTableFormatOptions = {
        auto: false,
        noScroll: false,
        colored: false,
        bordered: false,
        highlight: false,
        wide: true,
        narrow: false,
    };

    const columnHeadings: TableHeaderEntry[] =
        productType === ProductTypeLevel3.OL ? getOLColumnHeadings(t) : getColumnHeadings(t);

    const sortOptions: DynamicTableSortOptions<Installment> = {
        compare: (row1: Installment, row2: Installment) =>
            row1.installmentNumber !== undefined && row2.installmentNumber !== undefined
                ? row1.installmentNumber - row2.installmentNumber
                : 0,
        reversed: true,
    };

    return (
        <DynamicTable
            rows={groupPartialInstallments(installments)}
            columnHeadings={columnHeadings}
            formatOptions={formatOptions}
            sortOptions={sortOptions}
            toColumnValues={(row: GroupedInstallment): ToColumnValues =>
                productType === ProductTypeLevel3.OL ? generateOLRow(row, t, f) : generateRow(row, t, f)
            }
        />
    );
};
