import React from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleDetailsUi } from './ui';
import { AccordionItem } from '@cp-shared-5/frontend-ui';
import { VehicleDetails as VehicleDetailsType, ProductTypeLevel3 } from 'common';
import { NoConnectionNotification } from '../../../notifications/no-connection/NoConnectionNotification';

type VehicleDetailsProps = {
    vehicleDetails?: VehicleDetailsType;
    productTypeLevel3?: ProductTypeLevel3;
};

const expectedProductTypes: ProductTypeLevel3[] = [
    ProductTypeLevel3.OL,
    ProductTypeLevel3.FL,
    ProductTypeLevel3.ICVBW,
    ProductTypeLevel3.ICVBWO,
];

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({ vehicleDetails, productTypeLevel3 }) => {
    const { t } = useTranslation('vehicle-details');
    const hasError = !vehicleDetails;
    const areAllFieldsEmpty =
        !vehicleDetails?.vin &&
        !vehicleDetails?.logBookID &&
        !vehicleDetails?.depositedVWFSFlag &&
        !vehicleDetails?.logBookOwnerName &&
        !vehicleDetails?.registrationDate &&
        !vehicleDetails?.stkDate &&
        !vehicleDetails?.contractualMileageTotalKM &&
        !vehicleDetails?.contractualMileageToleranceKM;

    const isExpectedProductType: boolean = !!productTypeLevel3 && expectedProductTypes.includes(productTypeLevel3);

    if ((areAllFieldsEmpty && !hasError) || !isExpectedProductType) {
        return null;
    }

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-xsmall'}>
            {hasError ? (
                <NoConnectionNotification />
            ) : (
                <VehicleDetailsUi vehicleDetails={vehicleDetails} productTypeLevel3={productTypeLevel3} />
            )}
        </AccordionItem>
    );
};
