import { ConsentHookpage, getConsentHookpageEndpoint } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<ConsentHookpage>({
    contentName: 'ConsentHookpage',
    contentEndpoint: getConsentHookpageEndpoint,
});

export default reducer;
export const fetchConsentHookpage = fetchContent;
