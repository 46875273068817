import { AmortizationDetails, Contract, FinancialDetails } from 'common';
import { Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { AmortizationTableHeader } from '../header/AmortizationTableHeader';
import { useTranslation } from 'react-i18next';
import { AmortizationTableInstallmentOverview } from '../installment-overview/AmortizationTableInstallmentOverview';
import { AmortizationTableDownload } from '../download/AmortizationTableDownload';

export type AmortizationTableUiProps = {
    contract?: Contract;
    amortizationDetails?: AmortizationDetails;
    financialDetails?: FinancialDetails;
};

export const AmortizationTableUi: React.FC<AmortizationTableUiProps> = ({
    contract,
    amortizationDetails,
    financialDetails,
}) => {
    const { t } = useTranslation(['amortization-details', 'contracts']);
    // Check for contract being available, otherwise it might be that fetching hasn't even started
    if (!contract || !contract.isActive || !amortizationDetails || !financialDetails) {
        return null;
    }

    const { contractNumber, encryptedContractNumber, productTypeLevel3 } = contract;

    const { model = '', brand = '', licensePlate = '', fuelTypeCode, vehicleType } = contract.details.vehicle || {};

    const getFuelTypeText = !!fuelTypeCode ? `(${t(`contracts:fuel-type.${fuelTypeCode}`)})` : '';
    const getContractNumberText = !!contractNumber ? `${t('contracts:contract-number')}${contractNumber}` : '';

    const title = `${brand} ${model} ${vehicleType} ${getFuelTypeText}`.trim();

    return (
        <Layout>
            <Layout.Item default="1/1">
                <AmortizationTableHeader
                    carInformation={title}
                    contractCategory={
                        !!productTypeLevel3 ? t(`contracts:product-type-level-3.${productTypeLevel3}`) : ''
                    }
                    contractNumber={getContractNumberText}
                    countryCode={t('translation:country-code')}
                    registrationNumber={licensePlate}
                    financialDetails={financialDetails}
                    amortizationDetails={amortizationDetails}
                />
            </Layout.Item>
            <Layout.Item default="1/1">
                <AmortizationTableDownload
                    contractNumber={contractNumber}
                    encryptedContractNumber={encryptedContractNumber}
                />
            </Layout.Item>
            <Layout.Item default="1/1">
                <AmortizationTableInstallmentOverview
                    installments={amortizationDetails?.installments}
                    productType={productTypeLevel3}
                />
            </Layout.Item>
        </Layout>
    );
};
