import {
    ContractPartiesDetailsWithAllValues,
    ContractPartiesDetailsWithMissingValues,
} from './contract-parties/ExampleData';
import { ContractDetails } from 'common';
import {
    InstallmentCreditFinancialDetailsWithAllValues,
    InstallmentCreditFinancialDetailsWithAllValuesAndPaymentHoliday,
    InstallmentCreditFinancialDetailsWithMissingValues,
} from './financial-details/ExampleData';
import {
    VehicleDetailsForSkoda,
    VehicleDetailsWithAllValues,
    VehicleDetailsWithMissingValues,
} from './vehicle-details/ExampleData';
import { InsuranceDetailsWithAllValues, InsuranceDetailsWithMissingValues } from './insurance-details/ExampleData';
import { ServicesDetailsWithAllValues, ServicesDetailsWithMissingValues } from './services-details';

export const ContractDetailsWithAllValues: ContractDetails = {
    financial: InstallmentCreditFinancialDetailsWithAllValues,
    vehicle: VehicleDetailsWithAllValues,
    contractParties: ContractPartiesDetailsWithAllValues,
    insurances: InsuranceDetailsWithAllValues,
    services: ServicesDetailsWithAllValues,
};

export const ContractDetailsWithAllValuesAndPaymentHoliday: ContractDetails = {
    financial: InstallmentCreditFinancialDetailsWithAllValuesAndPaymentHoliday,
    vehicle: VehicleDetailsWithAllValues,
    contractParties: ContractPartiesDetailsWithAllValues,
    insurances: InsuranceDetailsWithAllValues,
    services: ServicesDetailsWithAllValues,
};

export const ContractDetailsWithAllValuesForSkoda: ContractDetails = {
    financial: InstallmentCreditFinancialDetailsWithAllValues,
    vehicle: VehicleDetailsForSkoda,
    contractParties: ContractPartiesDetailsWithAllValues,
    insurances: InsuranceDetailsWithAllValues,
    services: ServicesDetailsWithAllValues,
};

export const ContractDetailsWithMissingValues: ContractDetails = {
    financial: InstallmentCreditFinancialDetailsWithMissingValues,
    vehicle: VehicleDetailsWithMissingValues,
    contractParties: ContractPartiesDetailsWithMissingValues,
    insurances: InsuranceDetailsWithMissingValues,
    services: ServicesDetailsWithMissingValues,
};
