import React from 'react';

import { MarketingCard, Contract } from 'common';
import { Card, Button } from '@vwfs-bronson/bronson-react';
import { Carousel, openInNewWindow, useAnalyticsActionTracker } from '@cp-shared-5/frontend-ui';
import { FlickityOptions } from 'react-flickity-component';

export const MarketingCardsUi: React.FC<{ marketingCards?: MarketingCard[]; contracts?: Contract[] }> = ({
    marketingCards,
    contracts,
}) => {
    const { onAction } = useAnalyticsActionTracker('onLearnMore');

    if (!marketingCards) {
        return null;
    }

    if (contracts && contracts.filter((c) => c?.details?.vehicle?.brand?.toLowerCase() !== 'skoda').length === 0) {
        marketingCards = marketingCards.filter((card) => !card.imageUrl.includes('dashboard-marketing-card-4'));
    }

    const options: FlickityOptions = {
        adaptiveHeight: false,
        autoPlay: 10000,
    };

    return (
        <Carousel options={options}>
            {marketingCards.map((marketingCard, index) => (
                <Card
                    key={`marketingCard${index}`}
                    element="article"
                    title={marketingCard.title || ''}
                    imageSrc={marketingCard.imageUrl || ''}
                    footer={true}
                    buttons={
                        <Button
                            key={marketingCard.buttonUrl || 'isLoading'}
                            link={true}
                            href={marketingCard.buttonUrl}
                            icon="semantic-forward"
                            iconReversed
                            small
                            onClick={() => {
                                openInNewWindow(marketingCard.buttonUrl)();
                                onAction(marketingCard.title);
                            }}
                        >
                            {marketingCard.buttonLabel}
                        </Button>
                    }
                >
                    {marketingCard.text}
                </Card>
            ))}
        </Carousel>
    );
};
