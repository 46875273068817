import React from 'react';
import { FinancialDetails } from './financial-details';
import { VehicleDetails } from './vehicle-details';
import { InsuranceDetails } from './insurance-details';
import { Contract } from 'common';
import { ContractPartiesDetails } from './contract-parties';
import { ServicesDetails } from './services-details';

type ContractDetailsUiProps = {
    contract: Contract;
};

export const ContractDetails: React.FC<ContractDetailsUiProps> = ({ contract }) => {
    const { details, productTypeLevel3 } = contract;
    return (
        <>
            <FinancialDetails financialDetails={details.financial} productTypeLevel3={productTypeLevel3} />
            {contract.isActive && (
                <>
                    <VehicleDetails vehicleDetails={details?.vehicle} productTypeLevel3={productTypeLevel3} />
                    <InsuranceDetails insuranceDetails={details.insurances} financialDetails={details.financial} />
                    <ServicesDetails servicesDetails={details?.services} />
                    <ContractPartiesDetails contractParties={details?.contractParties} />
                </>
            )}
        </>
    );
};
