/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableHeaderEntry } from '@cp-shared-5/frontend-ui';
import { TFunction } from 'i18next';
import { TranslationFormat } from '../../../localization/useTranslationWithFormatting';
import { GroupedInstallment } from 'common';

export type ToColumnValues = (string | { value: string; hiddenForMobile: boolean; nowrap: boolean })[];

export const getColumnHeadings = (t: TFunction): TableHeaderEntry[] => {
    return [
        t('headings.installment-number'),
        t('headings.due-date'),
        t('headings.principal-sum'),
        t('headings.interest'),
        t('headings.services-fee'),
        t('headings.insurance'),
        t('headings.total-instalment'),
    ];
};

export const getOLColumnHeadings = (t: TFunction): TableHeaderEntry[] => {
    return [t('headings.installment-number'), t('headings.due-date'), t('headings.total-instalment-ol')];
};

export const generateOLRow = (row: GroupedInstallment, t: any, f: any): ToColumnValues => {
    const currencyCode = row.currencyCode;
    const currencyCodeTranslation = currencyCode ? t(`translation:currency.${currencyCode}`) : undefined;
    const getCurrencyOrDash = (currencyCodeTranslation: string, value?: number): string => {
        return typeof value === 'number' ? f(value, TranslationFormat.CURRENCY, currencyCodeTranslation) : '-';
    };
    return [
        f(row.installmentNumber, TranslationFormat.NUMERAL),
        row.dueDate ? f(row.dueDate, TranslationFormat.DATE) : '-',
        getCurrencyOrDash(currencyCodeTranslation, row.total),
    ];
};

export const generateRow = (row: GroupedInstallment, t: any, f: any): ToColumnValues => {
    const currencyCode = row.currencyCode;
    const currencyCodeTranslation = currencyCode ? t(`translation:currency.${currencyCode}`) : undefined;
    const getCurrencyOrDash = (currencyCodeTranslation: string, value?: number): string => {
        return typeof value === 'number' ? f(value, TranslationFormat.CURRENCY, currencyCodeTranslation) : '-';
    };
    return [
        f(row.installmentNumber, TranslationFormat.NUMERAL),
        row.dueDate ? f(row.dueDate, TranslationFormat.DATE) : '-',
        { value: getCurrencyOrDash(currencyCodeTranslation, row.principalSum), nowrap: true },
        { value: getCurrencyOrDash(currencyCodeTranslation, row.interest), nowrap: true },
        { value: getCurrencyOrDash(currencyCodeTranslation, row.servicesFee), nowrap: true },
        { value: getCurrencyOrDash(currencyCodeTranslation, row.insurance), nowrap: true },
        { value: getCurrencyOrDash(currencyCodeTranslation, row.total), nowrap: true },
    ];
};
