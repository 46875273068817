import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-5/frontend-storybook-extensions';

import {
    getAuthenticationEndpoint,
    RegistrationError,
    SendAuthorizationCodeError,
    getVerifyCodeEndpoint,
} from 'common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import {
    RegistrationResponseWithTooManyRequests,
    RegistrationResponseWithAlreadyRegisteredError,
    RegistrationResponseWithRequestTimeout,
    RegistrationResponseWithProductNotSupported,
    RegistrationResponseWithContractNotAllowed,
    RegistrationResponseWithIdentityDoesNotMatch,
    SendAuthorizationCodeExampleResponse,
    SendAuthorizationCodeWithInvalidPhoneNumberError,
} from './ExampleData';

const mockOptions: MockOptions<WithDefaultCpIntegrationErrors<RegistrationError>> = {
    Success: {
        status: 200,
        responseBody: SendAuthorizationCodeExampleResponse,
    },
    'With identity does not match': {
        status: 404,
        responseBody: RegistrationResponseWithIdentityDoesNotMatch,
    },
    'With contract not allowed': {
        status: 405,
        responseBody: RegistrationResponseWithContractNotAllowed,
    },
    'With product not supported': {
        status: 406,
        responseBody: RegistrationResponseWithProductNotSupported,
    },
    'With Request Timeout': {
        status: 408,
        responseBody: RegistrationResponseWithRequestTimeout,
    },
    'With already registered error': {
        status: 409,
        responseBody: RegistrationResponseWithAlreadyRegisteredError,
    },
    'With to many requests error': {
        status: 429,
        responseBody: RegistrationResponseWithTooManyRequests,
    },
    'Unexpected/Connection Error': {
        status: 500,
    },
};

const authorizationCodeMockOptions: MockOptions<WithDefaultCpIntegrationErrors<SendAuthorizationCodeError>> = {
    Success: {
        status: 200,
        responseBody: SendAuthorizationCodeExampleResponse,
    },
    'With invalid phone number': {
        status: 417,
        responseBody: SendAuthorizationCodeWithInvalidPhoneNumberError,
    },
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const VWFSVerificationMock: EndpointMockDescription<WithDefaultCpIntegrationErrors<RegistrationError>> = {
    name: 'Registration response',
    method: 'post',
    defaultMockOption: 'Success',
    mockOptions,
    url: getAuthenticationEndpoint(),
};

export const SendAuthorizationCodeMock: EndpointMockDescription<
    WithDefaultCpIntegrationErrors<SendAuthorizationCodeError>
> = {
    name: 'Send authorization code response',
    method: 'post',
    defaultMockOption: 'Success',
    mockOptions: authorizationCodeMockOptions,
    url: getVerifyCodeEndpoint(),
};

export const VWFSVerificationResponseMockWrapper = (storyFn: () => React.ReactNode): JSX.Element => {
    setupMockResponses(CpDataApi, [VWFSVerificationMock, SendAuthorizationCodeMock]);

    return <div>{storyFn()}</div>;
};
