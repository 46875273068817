import { getGreetingEndpoint } from 'common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-5/frontend-storybook-extensions';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { GreetingForBusinessCustomer, GreetingForPrivateCustomer } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: GreetingForPrivateCustomer,
    },
    'Success private customer': {
        status: 200,
        responseBody: GreetingForPrivateCustomer,
    },
    'Success business customer': {
        status: 200,
        responseBody: GreetingForBusinessCustomer,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const GreetingMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getGreetingEndpoint(),
};

export const GreetingResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [GreetingMock]);

    return <div>{storyFn()}</div>;
};
