import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@vwfs-bronson/bronson-react';
import { ValidatedSelect } from '@cp-shared-5/frontend-ui';
import { getAbroadTravelContractOptions } from '../utils';
import { Contract } from 'common';

type AbroadTravelFormSectionProps = { contracts: Contract[] };

export const AbroadTravelFormSection: React.FC<AbroadTravelFormSectionProps> = ({ contracts }) => {
    const { t } = useTranslation('open-request');

    return (
        <>
            <Layout.Item>
                <ValidatedSelect
                    label={t('form.contract-selection-label')}
                    testId={'custom-contract-select-abroad-travel'}
                    name="abroadTravel.contractNumber"
                    selectItems={getAbroadTravelContractOptions(contracts, t)}
                    emptyByDefault
                />
            </Layout.Item>
        </>
    );
};
