import React from 'react';
import { VehicleDetails, ProductTypeLevel3 } from 'common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { fieldsVisibilityMapping } from './fieldsVisibilityMapping';

type GeneralSectionProps = {
    vehicleDetails: VehicleDetails;
    productTypeLevel3: ProductTypeLevel3;
};

export const GeneralSection: React.FC<GeneralSectionProps> = ({ vehicleDetails, productTypeLevel3 }) => {
    const { t, f } = useTranslationWithFormatting('vehicle-details');

    const {
        vin,
        licensePlate,
        logBookID,
        depositedVWFSFlag,
        logBookOwnerName,
        registrationDate,
        stkDate,
        contractualMileageTotalKM,
        contractualMileageToleranceKM,
    } = vehicleDetails;

    const getValue = <T,>(
        name: keyof VehicleDetails,
        value: T | undefined,
        formattedValue?: string,
    ): T | string | undefined => {
        const shouldBeDisplayed: boolean = (fieldsVisibilityMapping[name] || []).includes(productTypeLevel3);
        if (!shouldBeDisplayed) return undefined;
        return formattedValue || value || '';
    };

    const itemList: DefinitionListItem[] = [
        {
            label: t('vin'),
            value: getValue<string>('vin', vin),
            testId: 'vehicle-details-vin',
        },
        {
            label: t('license-plate'),
            value: getValue<string>('licensePlate', licensePlate),
            testId: 'vehicle-details-license-plate',
        },
        {
            label: t('log-book-id'),
            value: getValue<string>('logBookID', logBookID),
            testId: 'vehicle-details-log-book-id',
        },
        {
            label: t('deposited-vwfs-flag.label'),
            value: getValue<'Y' | 'N' | undefined>(
                'depositedVWFSFlag',
                depositedVWFSFlag,
                t(`deposited-vwfs-flag.${depositedVWFSFlag}`),
            ),
            testId: 'vehicle-details-deposited-vwfs-flag',
        },
        {
            label: t('log-book-owner-name'),
            value: getValue<string>('logBookOwnerName', logBookOwnerName),
            testId: 'vehicle-details-log-book-owner-name',
        },
        {
            label: t('first-registration-date'),
            value: getValue<string>('registrationDate', registrationDate, f(registrationDate, TranslationFormat.DATE)),
            testId: 'vehicle-details-first-registration-date',
        },
        {
            label: t('stk-date'),
            value: getValue<string>('stkDate', stkDate, f(stkDate, TranslationFormat.DATE)),
            testId: 'vehicle-details-stk-date',
        },
        {
            label: t('contractual-mileage-total-km'),
            value: getValue<number>(
                'contractualMileageTotalKM',
                contractualMileageTotalKM,
                f(contractualMileageTotalKM, TranslationFormat.DISTANCE),
            ),
            testId: 'vehicle-details-contractual-mileage-total-km',
        },
        {
            label: t('contractual-mileage-tolerance-km'),
            value: getValue<number>(
                'contractualMileageToleranceKM',
                contractualMileageToleranceKM,
                f(contractualMileageToleranceKM, TranslationFormat.DISTANCE),
            ),
            testId: 'vehicle-details-contractual-mileage-tolerance-km',
        },
    ].filter(({ value }) => !!value);

    return <DefinitionListHorizontal list={itemList} />;
};
