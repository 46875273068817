import { PrivacyPolicy } from 'common';
import { HeroImage } from '@cp-shared-5/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';
import React from 'react';

export const PrivacyPolicyUi: React.FC<{ privacyPolicy?: PrivacyPolicy }> = ({ privacyPolicy }) => {
    if (!privacyPolicy) return null;

    const { teaser, content } = privacyPolicy;

    return (
        <>
            {teaser && <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={true} />}
            <ContentSection pageWrapSize="medium" className={'u-mt'}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </ContentSection>
        </>
    );
};
