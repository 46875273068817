import { VehicleDetails, ProductTypeLevel3 } from 'common';

export const fieldsVisibilityMapping: Partial<Record<keyof VehicleDetails, ProductTypeLevel3[]>> = {
    vin: [ProductTypeLevel3.OL, ProductTypeLevel3.FL, ProductTypeLevel3.ICVBW, ProductTypeLevel3.ICVBWO],
    licensePlate: [ProductTypeLevel3.OL, ProductTypeLevel3.FL, ProductTypeLevel3.ICVBW, ProductTypeLevel3.ICVBWO],
    logBookID: [ProductTypeLevel3.OL, ProductTypeLevel3.FL, ProductTypeLevel3.ICVBW, ProductTypeLevel3.ICVBWO],
    depositedVWFSFlag: [ProductTypeLevel3.FL, ProductTypeLevel3.ICVBW, ProductTypeLevel3.ICVBWO],
    logBookOwnerName: [ProductTypeLevel3.OL, ProductTypeLevel3.FL, ProductTypeLevel3.ICVBW, ProductTypeLevel3.ICVBWO],
    registrationDate: [ProductTypeLevel3.OL, ProductTypeLevel3.FL, ProductTypeLevel3.ICVBW, ProductTypeLevel3.ICVBWO],
    stkDate: [ProductTypeLevel3.OL, ProductTypeLevel3.FL, ProductTypeLevel3.ICVBW, ProductTypeLevel3.ICVBWO],
    contractualMileageTotalKM: [ProductTypeLevel3.OL],
    contractualMileageToleranceKM: [ProductTypeLevel3.OL],
};
