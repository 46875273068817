import { getStreetName, getStreetNumber } from '../AddressList';
import { Addresses } from 'common';
import { TFunction } from 'i18next';

export type AddressChangeForm = {
    isSameAddress: boolean;
    country: string;
    zipCode: string;
    street: string;
    streetNumber: string;
    city: string;
    files?: File[];
};

export const getSavedData = (addresses: Addresses, t: TFunction, isMainAddress?: boolean): AddressChangeForm => {
    if (!isMainAddress && addresses.contactAddress) {
        const street = getStreetName(addresses.contactAddress.street);
        const streetNumber = getStreetNumber(addresses.contactAddress.street);
        return {
            isSameAddress: false,
            country: addresses.contactAddress.country || t('addresses-section.edit-view.prefill-country'),
            city: addresses.contactAddress.city || '',
            street: street || '',
            streetNumber: streetNumber || '',
            zipCode: addresses.contactAddress.zipCode || '',
        };
    } else if (isMainAddress && addresses.mainAddress) {
        const street = getStreetName(addresses.mainAddress.street);
        const streetNumber = getStreetNumber(addresses.mainAddress.street);
        return {
            isSameAddress: false,
            country: addresses.mainAddress.country || t('addresses-section.edit-view.prefill-country'),
            city: addresses.mainAddress.city || '',
            street: street || '',
            streetNumber: streetNumber || '',
            zipCode: addresses.mainAddress.zipCode || '',
        };
    } else {
        return {
            isSameAddress: false,
            country: t('addresses-section.edit-view.prefill-country'),
            city: '',
            street: '',
            streetNumber: '',
            zipCode: '',
        };
    }
};
