import { AmortizationDetails } from 'common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-5/frontend-integration';
import { AxiosRequestConfig } from 'axios';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<
    AmortizationDetails,
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'payments',
    fetchCallback(link: string, requestConfig?: AxiosRequestConfig) {
        return CpDataApi.get(link, requestConfig).then((response) => response.data);
    },
});

export default reducer;
export const fetchPayments = fetchData;
