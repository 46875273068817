import React from 'react';
import { FONCustomer, FOPCustomer, Identification, POCustomer } from 'common';
import { DefinitionListItem, DataOverview, DefinitionListHorizontal } from '@cp-shared-5/frontend-ui';
import { useTranslation } from 'react-i18next';

export const IdentificationSection: React.FC<{ identification?: Identification }> = ({ identification }) => {
    const { t } = useTranslation('my-profile');

    if (!identification) {
        return null;
    }

    const getIdentificationList = (): DefinitionListItem[] => {
        switch (identification.type) {
            case 'NATURAL_PERSON':
                const dataFON = identification.data as FONCustomer;
                const customerIdFON = identification.customerId;
                return [
                    {
                        label: t('identification-section.customer-id'),
                        value: customerIdFON || '-',
                        testId: 'identification-customer-id',
                    },
                    {
                        label: t('identification-section.academic-title'),
                        value: dataFON.academicTitle || '-',
                        testId: 'identification-academic-title',
                    },
                    {
                        label: t('identification-section.full-name'),
                        value:
                            dataFON.firstName || dataFON.surname
                                ? `${dataFON.firstName ?? ''} ${dataFON.surname ?? ''}`
                                : '-',
                        testId: 'identification-name',
                    },
                ];
            case 'NATURAL_PERSON_WITH_LEGAL_PERSONALITY':
                const dataFOP = identification.data as FOPCustomer;
                const customerIdFOP = identification.customerId;
                return [
                    {
                        label: t('identification-section.customer-id'),
                        value: customerIdFOP || '-',
                        testId: 'identification-customer-id',
                    },
                    {
                        label: t('identification-section.company-id'),
                        value: dataFOP.companyId || '-',
                        testId: 'identification-company-id',
                    },
                    {
                        label: t('identification-section.vat-id'),
                        value: dataFOP.vatIdNumber || '-',
                        testId: 'identification-vat-id',
                    },
                    {
                        label: t('identification-section.full-name'),
                        value:
                            dataFOP.firstName || dataFOP.surname
                                ? `${dataFOP.firstName ?? ''} ${dataFOP.surname ?? ''}`
                                : '-',
                        testId: 'identification-name',
                    },
                ];
            case 'LEGAL_PERSON':
                const dataPO = identification.data as POCustomer;
                const customerIdPO = identification.customerId;
                return [
                    {
                        label: t('identification-section.customer-id'),
                        value: customerIdPO || '-',
                        testId: 'identification-customer-id',
                    },
                    {
                        label: t('identification-section.company-id'),
                        value: dataPO.companyId || '-',
                        testId: 'identification-company-id',
                    },
                    {
                        label: t('identification-section.vat-id'),
                        value: dataPO.vatIdNumber || '-',
                        testId: 'identification-vat-id',
                    },
                    {
                        label: t('identification-section.company-name'),
                        value: dataPO.companyName || '-',
                        testId: 'identification-company-id',
                    },
                ];
            default:
                return [];
        }
    };

    const identificationList: DefinitionListItem[] = getIdentificationList();

    if (!identificationList.length) {
        return null;
    }

    return (
        <DataOverview title={t('identification-section.title')}>
            <DefinitionListHorizontal list={identificationList} />
        </DataOverview>
    );
};
