import { Contract, ContractCategory, ProductTypeLevel3, StateConsolidatedCode } from 'common';
import {
    ContractDetailsWithAllValues,
    ContractDetailsWithAllValuesAndPaymentHoliday,
    ContractDetailsWithAllValuesForSkoda,
    ContractDetailsWithMissingValues,
} from './contract-details/ExampleData';

export const encryptedContractNumber = 'ASXDFSHEI121';

export const ActiveOLContractWithAllValues: Contract = {
    isActive: true,
    contractNumber: '123456',
    encryptedContractNumber: 'AXA1234H4123',
    productTypeLevel3: ProductTypeLevel3.OL,
    stateConsolidatedCode: StateConsolidatedCode.ACTCTR,
    contractCategoryName: ContractCategory.CONTRACT,
    details: ContractDetailsWithAllValues,
};

export const ActiveOLContractWithAllValuesAndPaymentHoliday: Contract = {
    ...ActiveOLContractWithAllValues,
    details: ContractDetailsWithAllValuesAndPaymentHoliday,
};

export const ActiveICVBWContractWithAllValues: Contract = {
    isActive: true,
    contractNumber: 'AICVBW1',
    encryptedContractNumber,
    productTypeLevel3: ProductTypeLevel3.ICVBW,
    stateConsolidatedCode: StateConsolidatedCode.ACTCTR,
    contractCategoryName: ContractCategory.CONTRACT,
    details: ContractDetailsWithAllValues,
};

export const ActiveICVBWContractWithAllValuesAndPaymentHoliday: Contract = {
    ...ActiveICVBWContractWithAllValues,
    details: ContractDetailsWithAllValuesAndPaymentHoliday,
};

export const ActiveFLContractWithAllValues: Contract = {
    isActive: true,
    contractNumber: 'AFL1',
    encryptedContractNumber: 'AXA1234H4111',
    productTypeLevel3: ProductTypeLevel3.FL,
    stateConsolidatedCode: StateConsolidatedCode.ACTCTR,
    contractCategoryName: ContractCategory.CONTRACT,
    details: ContractDetailsWithAllValues,
};

export const ActiveFLContractWithAllValuesAndPaymentHoliday: Contract = {
    ...ActiveFLContractWithAllValues,
    details: ContractDetailsWithAllValuesAndPaymentHoliday,
};

export const ActiveSkodaContractWithAllValues: Contract = {
    isActive: true,
    contractNumber: 'AFL1',
    encryptedContractNumber: 'AXA1234H4111',
    productTypeLevel3: ProductTypeLevel3.FL,
    stateConsolidatedCode: StateConsolidatedCode.ACTCTR,
    contractCategoryName: ContractCategory.CONTRACT,
    details: ContractDetailsWithAllValuesForSkoda,
};

export const InactiveOLContractWithAllValues: Contract = {
    ...ActiveOLContractWithAllValues,
    contractNumber: 'IAOL1',
    encryptedContractNumber: 'AXA1234H4112',
    isActive: false,
    stateConsolidatedCode: StateConsolidatedCode.TER,
    details: ContractDetailsWithAllValues,
};

export const InactiveICVBWContractWithAllValues: Contract = {
    ...ActiveICVBWContractWithAllValues,
    contractNumber: 'IAICVBW1',
    encryptedContractNumber: 'AXA1234H4113',
    isActive: false,
    stateConsolidatedCode: StateConsolidatedCode.CANCTR,
    details: ContractDetailsWithAllValues,
};

export const InactiveFLContractWithAllValues: Contract = {
    ...ActiveFLContractWithAllValues,
    contractNumber: 'IAFL1',
    encryptedContractNumber: 'AXA1234H4114',
    isActive: false,
    stateConsolidatedCode: StateConsolidatedCode.ETER,
    details: ContractDetailsWithAllValues,
};

export const ContractsWithAllValues: Contract[] = [
    ActiveOLContractWithAllValues,
    ActiveICVBWContractWithAllValues,
    ActiveFLContractWithAllValues,
    InactiveOLContractWithAllValues,
    InactiveICVBWContractWithAllValues,
    InactiveFLContractWithAllValues,
];

export const ContractsWithDifferentNumber: Contract[] = ContractsWithAllValues.map((contract: Contract) => ({
    ...contract,
    contractNumber: '654321',
    encryptedContractNumber: 'XYZ654321',
}));

export const ContractWithMissingValues: Contract = {
    ...ActiveFLContractWithAllValues,
    isActive: true,
    details: ContractDetailsWithMissingValues,
};

export const ContractsWithMissingValues: Contract[] = [ContractWithMissingValues];

export const ActiveContractForDebtNotification: Contract = {
    ...ActiveOLContractWithAllValues,
    stateConsolidatedCode: StateConsolidatedCode.FRZ,
};
