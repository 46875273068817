import {
    NotificationStatus,
    preventSubmit,
    ValidatedInput,
    Notification,
    useAnalyticsFormTracker,
    useAnalyticsActionTracker,
    CleaveInput,
} from '@cp-shared-5/frontend-ui';
import { Formik } from 'formik';
import { Fieldset, Form, Layout, Button } from '@vwfs-bronson/bronson-react';
import React, { ChangeEvent, useState } from 'react';
import { registrationValidationSchema } from 'common';
import { useTranslation } from 'react-i18next';
import { currentBrand } from 'config';
import { isEmpty, kebabCase } from 'lodash';
import { useTrackerOnBeforeUnload } from '../../../../hooks';

type IdentificationProps = {
    onSubmitSuccess: (formValues: IdentificationForm) => void;
    errorMessage: string;
};

export type IdentificationForm = {
    contractId: string;
    registrationPlateCode: string;
};

export const Identification: React.FC<IdentificationProps> = ({ onSubmitSuccess, errorMessage }) => {
    const { t } = useTranslation('registration');
    const { onTyping, onSubmit: trackOnSubmit } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
        confirm: 'onConfirmConfirmIdentiy',
    });
    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');
    const setLastTouchedField = useTrackerOnBeforeUnload();

    const [formValuesState, setFormValuesState] = useState<IdentificationForm>({
        contractId: '',
        registrationPlateCode: '',
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setFormValuesState({
            ...formValuesState,
            [e.target.name]: e.target.value,
        });
    };

    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);
    const initialListErrors = 'customerNumber, encryptedContractId, registrationPlateCode';

    const onSubmit = (formValuesState: IdentificationForm): void => {
        trackOnSubmit();
        onSubmitSuccess(formValuesState);
    };

    return (
        <>
            <Layout.Item className="u-text-center">
                <div dangerouslySetInnerHTML={{ __html: t(`identification.text-${currentBrand}`) }} />
            </Layout.Item>
            <Layout.Item>
                <Formik
                    initialValues={{
                        contractId: '',
                        registrationPlateCode: '',
                    }}
                    validationSchema={registrationValidationSchema}
                    onSubmit={onSubmit}
                >
                    {(formik) => (
                        <Form
                            onSubmit={preventSubmit()}
                            onChange={() => {
                                onTyping(formik.errors, formik.touched);
                            }}
                        >
                            <Fieldset>
                                <Fieldset.Row>
                                    <ValidatedInput
                                        isMandatory
                                        label={t('identification.form.contract-id.label')}
                                        name="contractId"
                                        testId={'contractId'}
                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                                        tooltip={t('identification.form.contract-id.tooltip')}
                                        onFocus={() => setLastTouchedField(t('identification.form.contract-id.label'))}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <CleaveInput
                                        isMandatory
                                        cleaveOptions={{
                                            uppercase: true,
                                            blocks: [8],
                                        }}
                                        label={t('identification.form.registration-plate.label')}
                                        name="registrationPlateCode"
                                        testId={'registrationPlateCode'}
                                        handleChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                                        onFocus={() =>
                                            setLastTouchedField(t('identification.form.registration-plate.label'))
                                        }
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <p data-testid="termsAndConditionsCP">
                                        {t('identification.form.terms-cp.label')}
                                        <a
                                            href={t('identification.form.terms-cp.linkAddress')}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t('identification.form.terms-cp.link')}
                                        </a>
                                        {t('identification.form.terms-cp.labelAfterLink')}
                                    </p>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <>
                                        {errorMessage && (
                                            <Notification
                                                testId={`identification.error.${kebabCase(errorMessage)}`}
                                                status={NotificationStatus.error}
                                                text={t(`identification.error-notification.${kebabCase(errorMessage)}`)}
                                            />
                                        )}
                                    </>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Button
                                        full={true}
                                        onClick={() => {
                                            formik.submitForm();
                                            if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                                const errorToString = getErrors(formik.errors).toString();
                                                if (!errorToString) {
                                                    onAction(initialListErrors);
                                                } else onAction(getErrors(formik.errors));
                                            }
                                        }}
                                        testId={'submitRegistrationButton'}
                                    >
                                        {t('identification.form.button-verify')}
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
            </Layout.Item>
        </>
    );
};
